<template>
	<div class="container">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'teams-index'}">Teams</router-link>
				</li>
			</ol>
		</nav>

		<vue-good-table
				:is-loading="loading"
				:columns="columns"
				:rows="teams" class="mb-3" @on-row-click="openTeam">
		</vue-good-table>

		<router-link :to="{name: 'teams-new'}" class="btn btn-primary" v-if="!loading && canWrite('teams')">
			<i class="fa fa-plus mr-2"></i>
			Add team
		</router-link>

	</div>
</template>

<script>
	import api from '@/api'

	export default {
		data() {
			return {
				teams: [],
				loading: false,
				columns: [
					{
						label: 'Team',
						field: 'Name',
					},
				]
			}
		},
		mounted() {
			this.refreshTeams()
		},
		methods: {
			openTeam(e){
				this.$router.push({name: 'teams-edit-uuid', params: {uuid: e.row.Id}})
			},
			async refreshTeams() {
				this.loading = true
				this.teams = await api.Teams.getList().finally(() => this.loading = false)
			}
		}
	}
</script>
